import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import BackgroundImage from 'gatsby-background-image'

import styles from './hero.module.css'

import IconArrowDown from '../images/icon-arrow-down-small.inline.svg'

const Hero = ({ backgroundImage, className, children, scrollTo }) => (
  <BackgroundImage
    Tag="div"
    className={classNames(styles.root, className)}
    fluid={backgroundImage}
  >
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {children}
        {scrollTo && (
          <a href={scrollTo} className={styles.scrollTo}>
            <IconArrowDown />
          </a>
        )}
      </div>
    </div>
  </BackgroundImage>
)

Hero.propTypes = {
  backgroundImage: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  scrollTo: PropTypes.string,
}

export default Hero
