import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import styles from './postCard.module.css'

import Image from './image'

const PostCard = ({ post }) => (
  <article key={post.id} className={styles.root} tabIndex={0}>
    <AniLink fade to={post.fields.slug} className={styles.link}>
      <Image
        node={post.frontmatter.featured_image.src}
        alt={post.frontmatter.featured_image.alt}
        className="framed-image"
      />
      <h2 className={styles.title}>{post.frontmatter.title}</h2>
      {post.frontmatter.author && (
        <p className={styles.author}>From: {post.frontmatter.author}</p>
      )}
    </AniLink>
  </article>
)

PostCard.propTypes = {
  post: PropTypes.object.isRequired,
}

export default PostCard
