import React from 'react'
import PropTypes from 'prop-types'

import Hero from './hero'

const HeroRecipes = ({ image, title, headline, scrollTo }) => (
  <Hero backgroundImage={image} scrollTo={scrollTo}>
    <h1 className="title title--white title--large">{title}</h1>
    {headline && <p className="lead">{headline}</p>}
  </Hero>
)

HeroRecipes.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  headline: PropTypes.string,
  scrollTo: PropTypes.string.isRequired,
}

export default HeroRecipes
