import React from 'react'
import PropTypes from 'prop-types'

import styles from './postsGrid.module.css'

import PostCard from './postCard'

const PostsGrid = ({ posts }) => {
  if (!posts.totalCount) {
    return null
  }

  return (
    <div id="posts" className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.grid}>
          {posts.edges.map(edge => (
            <PostCard key={edge.node.id} post={edge.node} />
          ))}
        </div>
      </div>
    </div>
  )
}

PostsGrid.propTypes = {
  posts: PropTypes.object.isRequired,
}

export default PostsGrid
