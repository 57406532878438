import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import styles from './pagination.module.css'

const Pagination = ({ label, slug, currentPage, numPages }) => {
  if (numPages <= 1) {
    return null
  }

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? slug : `${slug}/${currentPage - 1}`
  const nextPage = `${slug}/${currentPage === 0 ? '' : currentPage + 1}`

  return (
    <nav aria-label={label}>
      <ol className={styles.root}>
        {!isFirst && (
          <li>
            <AniLink
              fade
              to={prevPage}
              rel="prev"
              className="button button--inverse"
            >
              ← Previous
            </AniLink>
          </li>
        )}
        {Array.from({ length: numPages }, (_, i) => {
          const pageNumber = i === 0 ? '' : i + 1

          return (
            <li key={`pagination-number${i + 1}`}>
              <AniLink
                fade
                to={`${slug}/${pageNumber}`}
                className="button button--inverse button--pagination"
                aria-label={
                  pageNumber === ''
                    ? 'Go to First Page'
                    : `Go to Page ${pageNumber}`
                }
              >
                {i + 1}
              </AniLink>
            </li>
          )
        })}
        {!isLast && (
          <li>
            <AniLink
              fade
              to={nextPage}
              rel="next"
              className="button button--inverse"
            >
              Next →
            </AniLink>
          </li>
        )}
      </ol>
    </nav>
  )
}

Pagination.propTypes = {
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
}

export default Pagination
