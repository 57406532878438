import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroRecipes from '../components/heroRecipes'
import PostsGrid from '../components/postsGrid'
import Pagination from '../components/pagination'

export default ({ pageContext, data }) => {
  const page = data.page.childContentJson
  const posts = data.posts

  return (
    <Layout>
      <SEO
        title={page.title}
        seoTitle={page.seo_title}
        description={page.seo_description}
      />
      <HeroRecipes
        image={page.featured_image.src.childImageSharp.fluid}
        title={page.title}
        headline={page.headline}
        scrollTo="#posts"
      />
      <PostsGrid posts={posts} />
      <Pagination
        label="Posts Pagination Navigation"
        slug={pageContext.slug}
        currentPage={pageContext.currentPage}
        numPages={pageContext.numPages}
      />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    page: file(relativePath: { eq: "content/posts.json" }) {
      childContentJson {
        title
        headline
        seo_title
        seo_description
        featured_image {
          src {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1680) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "post" } } }
      sort: { fields: [frontmatter___sort], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            author
            featured_image {
              src {
                publicURL
                childImageSharp {
                  fixed(width: 320, height: 320) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  }
`
